import { AppBar } from "@mui/material";
import Typography from "@mui/material/Typography";
import * as React from 'react';
import Box from '@mui/material/Box';
import CssBaseline from '@mui/material/CssBaseline';
import Divider from '@mui/material/Divider';
import Drawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import MenuIcon from '@mui/icons-material/Menu';
import Toolbar from '@mui/material/Toolbar';
import Button from '@mui/material/Button';
import { useNavigate } from "react-router-dom";

const drawerWidth = 240;

interface NavItem {
    path: string;
    text: string;
}

const navItems: NavItem[] = [
    {text: 'Routine AI', path: '/coach'},
    {text: 'Social Scripts', path: '/socialScripts'},
    { text: 'Social Simulator', path: '/socialSimulator' },    
    { text: 'Deck Builder', path: '/deck' },    
    { text: "Scene Maker", path: '/sceneMaker' },    
    { text: 'English Repeat', path: '/repeatEnglish' },
];

const unselectedColor = '#8c8c8c';
const selectedColor = '#000000';

export function NavBar() {
    const [mobileOpen, setMobileOpen] = React.useState(false);
    const navigate = useNavigate();
    const curLocation = window.location.pathname;

    const defaultNav = navItems[0];

    const handleDrawerToggle = () => {
        setMobileOpen((prevState) => !prevState);
    };

    const onNavItemClick = (path: string) => {
        navigate(path);
    };

    const isSelected = (item: NavItem) => {
        return curLocation === item.path || (curLocation === '/' && item === defaultNav);
    };

    const drawer = (
        <Box onClick={handleDrawerToggle} sx={{ textAlign: 'center' }}>
            <Typography variant="h6" sx={{ my: 2 }} color={selectedColor}>
                Bits&Bots
            </Typography>
            <Divider />
            <List>
                {navItems.map((item) => (
                    <ListItem key={item.path} disablePadding>
                        <ListItemButton
                            sx={{ textAlign: 'center', color: isSelected(item) ? selectedColor : unselectedColor }}
                            onClick={() => onNavItemClick(item.path)}
                        >
                            <ListItemText primary={item.text} />
                        </ListItemButton>
                    </ListItem>
                ))}
            </List>
        </Box>
    );

    return (
        <Box sx={{ display: 'flex' }}>
            <CssBaseline />
            <AppBar component="nav" position="static" sx={{ backgroundColor: 'transparent', boxShadow: 'none' }}>
                <Toolbar>
                    <IconButton
                        color="primary"
                        aria-label="open drawer"
                        edge="start"
                        onClick={handleDrawerToggle}
                        sx={{ mr: 2, display: { sm: 'none' } }}
                    >
                        <MenuIcon />
                    </IconButton>
                    <Typography
                        variant="h6"
                        component="div"
                        color="black"
                        sx={{ flexGrow: 1, display: { xs: 'none', sm: 'block' } }}
                    >
                        Bits&Bots
                    </Typography>
                    <Box sx={{ display: { xs: 'none', sm: 'block' } }}>
                        {navItems.map((item) => (
                            <Button
                                key={item.path}
                                sx={{ color: isSelected(item) ? selectedColor : unselectedColor }}
                                onClick={() => onNavItemClick(item.path)}
                            >
                                {item.text}
                            </Button>
                        ))}
                    </Box>
                </Toolbar>
            </AppBar>
            <nav>
                <Drawer
                    variant="temporary"
                    open={mobileOpen}
                    onClose={handleDrawerToggle}
                    ModalProps={{
                        keepMounted: true, // Better open performance on mobile.
                    }}
                    sx={{
                        display: { xs: 'block', sm: 'none' },
                        '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
                    }}
                >
                    {drawer}
                </Drawer>
            </nav>
        </Box>
    );
}
