import React from 'react';
import { Navigate } from 'react-router-dom';
import { useAuth0 } from '@auth0/auth0-react';

interface RedirectRouteProps {
  children: React.ReactNode;
}

const RedirectRoute = ({ children }: RedirectRouteProps) => {
  const { isAuthenticated } = useAuth0();

  if (isAuthenticated) {    
    const intendedRoute = sessionStorage.getItem('intendedRoute') || '/coach';

    sessionStorage.removeItem('intendedRoute');
    return <Navigate to={intendedRoute} replace />;
  }

  return <>{children}</>;
};

export default RedirectRoute;
