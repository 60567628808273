import { Typography } from "@mui/material";
import { useState, useEffect } from "react";
import { RoutineBuilder } from "./RoutineBuilder";
import { CallingScreen } from "./CallingScreen";
import { SaveScreen } from "./SaveScreen";
import RoutinesScreen from "./SavedRoutines";
import { useAuth0 } from "@auth0/auth0-react";
import { Routine, saveRoutine } from "./serverLogic";

enum VoiceCoachState {
    LOGIN,
    CREATE,
    CALLING,
    SAVING,
    EXISTING_ROUTINES
}

export function VoiceCoach() {
    const [state, setState] = useState<VoiceCoachState>(VoiceCoachState.CREATE);

    const [routineName, setRoutineName] = useState<string>("");
    const [personName, setPersonName] = useState<string>("");
    const [phoneNumber, setPhoneNumber] = useState<string>("");
    const [createdTasks, setCreatedTasks] = useState<string[]>([]);
    const [editingRoutineId, setEditingRoutineId] = useState<string | null | undefined>(null);
    const [shouldSendEmail, setShouldSendEmail] = useState<boolean>(true);

    const [requireStrictConfirmation, setRequireStrictConfirmation] = useState<boolean>(false);
    
    const [reminderTime, setReminderTime] = useState<Date | null>(null);
    const [repeatDaily, setRepeatDaily] = useState<boolean>(false);
    const [runAlarmOnce, setRunAlarmOnce] = useState<boolean>(false);
    const [speakInSimpleSentences, setSpeakInSimpleSentences] = useState<boolean>(false);

    

    const user = useAuth0()
    const email = user.user?.email || "";

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [state]);

    function onCall() {
        setState(VoiceCoachState.CALLING);
    }

    function onSave() {
        setState(VoiceCoachState.SAVING);
    }

    function onGoToSavedRoutines() {
        setState(VoiceCoachState.EXISTING_ROUTINES);
    }

    function resetAll() {
        setRoutineName("");
        setPersonName("");
        setPhoneNumber("");
        setCreatedTasks([]);
        setShouldSendEmail(true);
        setReminderTime(null);
        setRepeatDaily(false);
        setRunAlarmOnce(false);
        setEditingRoutineId(null);
        setRequireStrictConfirmation(false);
        setSpeakInSimpleSentences(false);
    }

    function onEdit(routine: Routine) {
        setRoutineName(routine.routineName);
        setPersonName(routine.personName);
        setPhoneNumber(routine.phoneNumber);
        setCreatedTasks(routine.tasks);
        setEditingRoutineId(routine.id)
        setShouldSendEmail(routine.shouldSendEmail);        
        setReminderTime(routine.reminderTime || null);
        setRepeatDaily(routine.repeatDaily || false);
        setRunAlarmOnce(routine.runAlarmOnce || false);        
        setRequireStrictConfirmation(routine.requireStrictConfirmation || false);
        setSpeakInSimpleSentences(routine.speakInSimpleSentences || false);

        setState(VoiceCoachState.CREATE);
        
    }

    function onConfirmSave(triggerDaily: boolean, runAlarmOnce: boolean, time: Date) {
        const userId = user.user?.sub
        if (!userId) {
            console.error("User ID not found");
            return;
        }

        const routine: Routine = {
            id: editingRoutineId ?? null,
            userId: userId,
            routineName: routineName,
            tasks: createdTasks,
            reminderTime: time,
            repeatDaily: triggerDaily,
            personName: personName,
            phoneNumber: phoneNumber,
            shouldSendEmail: shouldSendEmail,
            emailAddress: email,
            runAlarmOnce: runAlarmOnce,
            requireStrictConfirmation: requireStrictConfirmation,
            speakInSimpleSentences: speakInSimpleSentences
        };

        saveRoutine(routine);
        console.log("Trigger Daily:", triggerDaily);
        console.log("Scheduled Time:", time.toISOString());

        resetAll();
        setState(VoiceCoachState.CREATE);
    }

    if (state === VoiceCoachState.CREATE) {
        return (
            <RoutineBuilder
                onCall={onCall}
                routineName={routineName}
                setRoutineName={setRoutineName}
                personName={personName}
                setPersonName={setPersonName}
                phoneNumber={phoneNumber}
                setPhoneNumber={setPhoneNumber}
                createdTasks={createdTasks}
                setCreatedTasks={setCreatedTasks}
                shouldSendEmail={shouldSendEmail}
                setShouldSendEmail={setShouldSendEmail}
                onSave={onSave}
                onGoToSavedRoutines={onGoToSavedRoutines}
                editingRoutineId={editingRoutineId ?? undefined}
                onCancelEditing={() => { resetAll() }}
                requireStrictConfirmation={requireStrictConfirmation}
                setRequireStrictConfirmation={setRequireStrictConfirmation}
                speakInSimpleSentences={speakInSimpleSentences}
                setSpeakInSimpleSentences={setSpeakInSimpleSentences}
            />
        );
    } else if (state === VoiceCoachState.CALLING) {
        return (
            <CallingScreen onBack={() => { setState(VoiceCoachState.CREATE); }} />
        );
    } else if (state === VoiceCoachState.SAVING) {
        return (
            <SaveScreen
                onBack={() => { setState(VoiceCoachState.CREATE); }}
                onConfirm={onConfirmSave}
                reminderTime={reminderTime}
                repeatDaily={repeatDaily}
                runAlarmOnce={runAlarmOnce}
            />
        );
    } else if (state === VoiceCoachState.EXISTING_ROUTINES) {
        return (
            <RoutinesScreen onBack={() => setState(VoiceCoachState.CREATE)}
                onCall={() => { setState(VoiceCoachState.CALLING) }} 
            onEdit={onEdit}/>
        );
    }

    return (
        <Typography textAlign={'center'}>
            ERROR!
        </Typography>
    );
}
