import { Typography, Container, Button, Switch, Stack, FormControlLabel, TextField } from "@mui/material";
import { useState } from "react";

function getTimeZoneAbbreviation() {
    const date = new Date();
    const timeZoneString = date.toLocaleTimeString('en-us', { timeZoneName: 'short' }).split(' ')[2];
    return timeZoneString;
}

interface SaveScreenProps {
    onBack: () => void;
    onConfirm: (triggerDaily: boolean, runAlarmOnce: boolean, time: Date) => void;
    reminderTime?: Date | null;
    repeatDaily?: boolean;
    runAlarmOnce?: boolean;
}


export function SaveScreen(props: SaveScreenProps) {
    const [triggerDaily, setTriggerDaily] = useState<boolean>(props.repeatDaily ?? false);
    const [runAlarmOnce, setRunAlarmOnce] = useState<boolean>(props.runAlarmOnce ?? false);
    const [time, setTime] = useState<string | null>(
        props.reminderTime
            ? `${props.reminderTime.getHours().toString().padStart(2, '0')}:${props.reminderTime.getMinutes().toString().padStart(2, '0')}`
            : null
    );

    const timeZoneAbbreviation = getTimeZoneAbbreviation();
    const timeZoneCityStyle = Intl.DateTimeFormat().resolvedOptions().timeZone;

    const handleConfirm = () => {
        if ((triggerDaily || runAlarmOnce) && time) {
            const [hours, minutes] = time.split(':');
            const now = new Date();
            now.setHours(parseInt(hours), parseInt(minutes), 0, 0);
            props.onConfirm(triggerDaily, runAlarmOnce, now);
        } else if (!triggerDaily && !runAlarmOnce) {
            const now = new Date();
            props.onConfirm(false, false, now);
        }
    };

    const isConfirmDisabled = (triggerDaily || runAlarmOnce) && time === null;

    return (
        <Container sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', marginTop: '20px' }}>
            <Typography variant="h4" textAlign={'center'}>Save Routine</Typography>
            <Typography variant="h6" textAlign={'center'} marginTop={'20px'}>
                You can save this routine and run it manually.<br /><br />
                Or, you can set an alarm to run the routine. You can make this alarm run one time or you can make it run at the same time every day.
            </Typography>

            {(triggerDaily || runAlarmOnce) && (
                <Stack direction={'column'} alignItems="center" sx={{ marginTop: '20px', width: '100%' }}>
                    <TextField
                        type="time"
                        label="Select Time"
                        InputLabelProps={{
                            shrink: true,
                        }}
                        value={time ?? ""}
                        onChange={(event) => setTime(event.target.value)}
                        sx={{ width: '200px', backgroundColor: 'white' }}
                    />
                    <Typography sx={{ marginTop: '10px' }}>
                        Your Time Zone: {timeZoneAbbreviation}
                    </Typography>
                    <Typography sx={{ marginTop: '5px' }}>
                        ({timeZoneCityStyle})
                    </Typography>
                </Stack>
            )}

            <FormControlLabel
                control={
                    <Switch
                        checked={runAlarmOnce}
                        onChange={(event) => {
                            const newRunAlarmOnce = event.target.checked;
                            setRunAlarmOnce(newRunAlarmOnce);
                            if (newRunAlarmOnce) {
                                setTriggerDaily(false);
                            }                            
                        }}
                    />
                }
                label="Run Alarm Once"
                sx={{ marginTop: '10px' }}
            />

            <FormControlLabel
                control={
                    <Switch
                        checked={triggerDaily}
                        onChange={(event) => {
                            const newTriggerDaily = event.target.checked;
                            setTriggerDaily(newTriggerDaily);
                            if (newTriggerDaily) {
                                setRunAlarmOnce(false);
                            }                            
                        }}
                    />
                }
                label="Run Alarm Daily"
                sx={{ marginTop: '20px' }}
            />                        

            <Stack direction={'row'} spacing={2} sx={{ marginTop: '30px' }}>
                <Button variant="contained" onClick={props.onBack}>Back</Button>
                <Button 
                    variant="contained" 
                    color="primary" 
                    onClick={handleConfirm}
                    disabled={isConfirmDisabled}
                >
                    Confirm
                </Button>
            </Stack>
        </Container>
    );
}
