import axios from 'axios';
import { initializeApp } from 'firebase/app';
import { getFirestore } from 'firebase/firestore';
import { collection, getDocs, query, where, addDoc, updateDoc, doc, deleteDoc } from 'firebase/firestore';
import mixpanel from 'mixpanel-browser';
import { connectFirestoreEmulator } from 'firebase/firestore';

const firebaseConfig = {
  apiKey: "AIzaSyD6-aU_uME6OioiQdJZx22F4LItcxUiKW8",
  authDomain: "bitsandbots.firebaseapp.com",
  projectId: "bitsandbots",
  storageBucket: "bitsandbots.appspot.com",
  messagingSenderId: "889167914862",
  appId: "1:889167914862:web:8decea2efcb8e69d68c37f",
  measurementId: "G-F9WE4J599Q"
};

export interface Routine {
  id?: string | null;
  userId: string;
  routineName: string;
  tasks: string[];
  reminderTime: Date;
  repeatDaily: boolean;
  personName: string;
  phoneNumber: string;
  shouldSendEmail: boolean;  
  emailAddress: string;     
  requireStrictConfirmation: boolean;
  runAlarmOnce?: boolean; 
  speakInSimpleSentences: boolean
}

const app = initializeApp(firebaseConfig);
const db = getFirestore(app);

// connectFirestoreEmulator(db, 'localhost', 8080);


export async function deleteRoutine(routineId: string, routineName: string): Promise<void> {
  try {
      const routineRef = doc(db, 'routines', routineId);
      await deleteDoc(routineRef);
      console.log('Routine deleted:', routineId);
      mixpanel.track("RoutineDeleted", { routineId, routineName });
  } catch (error) {
      console.error('Error deleting routine:', error);
  }
}

export async function fetchRoutines(userId: string): Promise<Routine[]> {
  const routines: Routine[] = [];
  try {
    const q = query(collection(db, 'routines'), where('userId', '==', userId));
    const querySnapshot = await getDocs(q);

    querySnapshot.forEach((doc) => {
      const data = doc.data();
      routines.push({
        id: doc.id ?? null,
        userId: data.userId,
        routineName: data.routineName,
        tasks: data.tasks,
        reminderTime: data.reminderTime.toDate(),
        repeatDaily: data.repeatDaily,
        personName: data.personName,
        phoneNumber: data.phoneNumber,
        shouldSendEmail: data.shouldSendEmail ?? false,
        emailAddress: data.emailAddress ?? "",
        runAlarmOnce: data.runAlarmOnce ?? false, 
        requireStrictConfirmation: data.requireStrictConfirmation ?? false,
        speakInSimpleSentences: data.speakInSimpleSentences ?? false
      });
    });

    console.log('Fetched routines:', routines);
  } catch (error) {
    console.error('Error fetching routines:', error);
  }

  return routines;
}

export async function saveRoutine(routine: Routine) {
  console.log('Saving routine:', routine);
  
  try {
    const routineData = { //TODO: Make this just take all the fields of the routine - currently, if you add something to routine & forget to add to this, save breaks
      userId: routine.userId,
      routineName: routine.routineName,
      tasks: routine.tasks,
      reminderTime: routine.reminderTime,
      repeatDaily: routine.repeatDaily,
      personName: routine.personName,
      phoneNumber: routine.phoneNumber,
      shouldSendEmail: routine.shouldSendEmail,
      emailAddress: routine.emailAddress,
      runAlarmOnce: routine.runAlarmOnce,
      requireStrictConfirmation: routine.requireStrictConfirmation,
      speakInSimpleSentences: routine.speakInSimpleSentences
    };

    if (routine.id) {
      console.log('updating existing routine with id', routine.id);
      mixpanel.track("RoutineUpdated", routineData)
      await updateDoc(doc(db, "routines", routine.id), routineData);
    } else {
      await addDoc(collection(db, "routines"), routineData);
    }

    console.log('Routine saved successfully');
  } catch (error) {
    console.error('Error saving routine:', error);
  }
}


export async function fetchAllSavedRoutines(userId: string): Promise<Routine[]> {
  const routines: Routine[] = [];
  try {
    const q = query(collection(db, 'routines'), where('userId', '==', userId));
    const querySnapshot = await getDocs(q);

    querySnapshot.forEach((doc) => {
      const data = doc.data();
      routines.push({
        id: doc.id ?? null,
        userId: data.userId,
        routineName: data.routineName,
        tasks: data.tasks,
        reminderTime: data.reminderTime.toDate(), 
        repeatDaily: data.repeatDaily,
        personName: data.personName,
        phoneNumber: data.phoneNumber,
        shouldSendEmail: data.shouldSendEmail ?? false,
        emailAddress: data.emailAddress ?? "",
        runAlarmOnce: data.runAlarmOnce ?? false,
        requireStrictConfirmation: data.requireStrictConfirmation ?? false,
        speakInSimpleSentences: data.speakInSimpleSentences ?? false
      });
    });

    console.log('Fetched routines:', routines);
  } catch (error) {
    console.error('Error fetching routines:', error);
  }

  return routines;
}


const blandHeaders = {
  'Authorization': 'sk-e4dczy3jflt2mgj4354ds21wiqslng4yluya9fcn3cz7bmlnqj0z5bk77fgi8cgg69'
};

const blandCallData = {
  "phone_number": "",
  "from": "+14153225299",
  "task": "",
  "model": "enhanced",
  "language": "en",
  "voice": "nat",
  "voice_settings": {},
  "pathway_id": null,
  "local_dialing": false,
  "max_duration": 6,
  "answered_by_enabled": false,
  "wait_for_greeting": true,
  "record": false,
  "amd": false,
  "interruption_threshold": 100,
  "voicemail_message": null,
  "temperature": null,
  "transfer_phone_number": null,
  "transfer_list": {},
  "metadata": {},
  "pronunciation_guide": [],
  "start_time": null,
  "request_data": {},
  "tools": [],
  "dynamic_data": [],
  "analysis_schema": {},
  "webhook": "https://completecall-lkjcclx3ma-uc.a.run.app",  
};

interface Params {
  numberToCall: string;
  taskList: string[];
  personName: string;
  routineName: string;
  emailToNotify: string;
  shouldSendEmail: boolean; 
  requireStrictConfirmation: boolean
  speakInSimpleSentences: boolean
}

export async function makeCall(params: Params) {
  

  const confirmationString = params.requireStrictConfirmation ? `\n-Don’t move on to the next task until ${params.personName} has said, “Yes, I am done. Remind them to say that if they say any other form of confirmation. They must say that sentence before you can proceed to the next task. If they say yes without saying "I am done" tell them they need to say "yes I am done" before you can move on. They can also say "Yes, I'm done."` : "";

const simpleSentencesString = params.speakInSimpleSentences ? `\n-Speak in short, simple sentences for a 6 year old child. All of your responses should be 20 words or shorter.` : "";


  const taskListStringified = params.taskList
    .map((task, index) => `${index + 1}. ${task}`)
    .join('\n');

  const task = `OBJECTIVES
-You are an AI employed to help ${params.personName} complete their routine for ${params.routineName}.
-Follow the task list, but DO NOT mention the next task until you have gotten a clear confirmation that ${params.personName} has finished the current task. Do not bring up the next task.
-DO NOT end the call until you have gone through all of the tasks in the list.
-Introduce yourself as Echo the AI and greet ${params.personName} by name.
${confirmationString}${simpleSentencesString}

Remember that this isn't perfect and that certain situations or objections may come up. Handle them with grace and bring the conversation back to finishing the [TASK].

LIST OF TASKS
Here is the list of tasks you are going to go through. DO NOT proceed with a task until ${params.personName} has confirmed that they have finished the prior task. Do not bring up the next task until ${params.personName} has clearly stated that they finished the last task.

${taskListStringified}

After all the tasks are completed, say Good Job then hang up immediately.

For example
You: Great. You've completed all the tasks. Good job!
(Hangup)
`;

console.log("Params: ", params);
console.log("Task: ", task);

  blandCallData.phone_number = params.numberToCall;
  blandCallData.task = task;

  try {
    mixpanel.track("CallMade", { personName: params.personName, routineName: params.routineName, 
      phoneNumber: params.numberToCall, taskList: params.taskList, emailToNotify: params.emailToNotify, shouldSendEmail: params.shouldSendEmail });
    console.log("Making API Call. Task: ", blandCallData.task);
    const response = await axios.post('https://api.bland.ai/v1/calls', blandCallData, { headers: blandHeaders });
    console.log(response.data);
  
    console.log("Writing to firestore: ", response.data.call_id);
    await addDoc(collection(db, 'Calls'), {
      callId: response.data.call_id,
      routineName: params.routineName,
      taskList: params.taskList,
      personName: params.personName,
      phoneNumber: params.numberToCall,
      emailToNotify: params.emailToNotify || '',
      shouldSendEmail: params.shouldSendEmail, 
      timestamp: new Date(),
    });
    console.log('Call details logged to Firestore');
  } catch (error) {
    console.error('Error making the API call or writing to Firestore:', error);
  }
  
}
